// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-authenticate-js": () => import("./../../../src/pages/authenticate.js" /* webpackChunkName: "component---src-pages-authenticate-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-high-frequency-details-js": () => import("./../../../src/pages/high_frequency_details.js" /* webpackChunkName: "component---src-pages-high-frequency-details-js" */),
  "component---src-pages-high-frequency-js": () => import("./../../../src/pages/high_frequency.js" /* webpackChunkName: "component---src-pages-high-frequency-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liquidized-robot-list-js": () => import("./../../../src/pages/liquidized_robot_list.js" /* webpackChunkName: "component---src-pages-liquidized-robot-list-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-product-high-frequency-detail-index-js": () => import("./../../../src/pages/product/high_frequency/detail/index.js" /* webpackChunkName: "component---src-pages-product-high-frequency-detail-index-js" */),
  "component---src-pages-transaction-js": () => import("./../../../src/pages/transaction.js" /* webpackChunkName: "component---src-pages-transaction-js" */)
}

