export const PRODUCT_UPDATE_ACTIVEID = "PRODUCT_UPDATE_ACTIVEID";
export const DEPOSIT_UPDATE_ACTIVEID = "DEPOSIT_UPDATE_ACTIVEID";
export const WITHDRAWAL_UPDATE_ACTIVEID = "WITHDRAWAL_UPDATE_ACTIVEID";
export const ASSET_TAB_ACTIVEID = "ASSET_TAB_ACTIVEID";
export const SIMULATE_ROBOT_ACTIVEID = "SIMULATE_ROBOT_ACTIVEID";
export const APIKEY_UPDATE_ACTIVEID = "APIKEY_UPDATE_ACTIVEID";
export const TEMP_PAGINATION = "TEMP_PAGINATION";

export function updateActiveId(id) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_UPDATE_ACTIVEID,
      id,
    });
  };
}

export function updateDepositActiveId(id) {
  return (dispatch) => {
    dispatch({
      type: DEPOSIT_UPDATE_ACTIVEID,
      id,
    });
  };
}
export function updateWithdrawalActiveId(id) {
  return (dispatch) => {
    dispatch({
      type: WITHDRAWAL_UPDATE_ACTIVEID,
      id,
    });
  };
}

export function updateTabActiveId(id) {
  return (dispatch) => {
    dispatch({
      type: ASSET_TAB_ACTIVEID,
      id,
    });
  };
}

export function updateSimulateRobotActiveId(id) {
  return (dispatch) => {
    dispatch({
      type: SIMULATE_ROBOT_ACTIVEID,
      id,
    });
  };
}

export function updateApiKeyActiveId(id) {
  return (dispatch) => {
    dispatch({
      type: APIKEY_UPDATE_ACTIVEID,
      id,
    });
  };
}

export function updatePreviousPagination(data) {
  return (dispatch) => {
    dispatch({
      type: TEMP_PAGINATION,
      data,
    });
  };
}
