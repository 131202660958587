export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL";
export const UPDATE_USER = "UPDATE_USER";
export const PAIRING_LOGIN = "PAIRING_LOGIN";
export const LOGIN_TIME = "LOGIN_TIME";

export function loginSuccessful(user, accessKey,timestamp) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESSFUL,
      user,
      accessKey,
      timestamp
    });
  };
}

export function updateLoginTime() {
  return (dispatch) => {
    dispatch({
      type: LOGIN_TIME,
    });
  };
}

export function logoutSuccessful() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_SUCCESSFUL,
    });
  };
}

export function updateUser(user) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER,
      user,
    });
  };
}

export function pairingLogin(status) {
  return (dispatch) => {
    dispatch({
      type: PAIRING_LOGIN,
      status,
    });
  };
}
