import localStorage from "local-storage";
import { get, isEqual } from "lodash";
import {
  APIKEY_UPDATE_ACTIVEID,
  ASSET_TAB_ACTIVEID,
  DEPOSIT_UPDATE_ACTIVEID,
  PRODUCT_UPDATE_ACTIVEID,
  SIMULATE_ROBOT_ACTIVEID,
  WITHDRAWAL_UPDATE_ACTIVEID,
  TEMP_PAGINATION,
} from "../actions/product-actions";
import { persistRedux } from "../config";

const INITIAL_STATE = {
  activeId: "",
  depositActiveId: "",
  withdrawalActiveId: "",
  tabActiveId: "0",
  simulateRobotActiveId: "",
  apiKeyActiveId: "",
  robotListing: {},
};
export default function (state = INITIAL_STATE, action) {
  let persistStates =
    get(localStorage.get("redux") || {}, "product") || INITIAL_STATE;
  let newState = {
    ...state,
    ...persistStates,
  };

  if (!isEqual(state, newState)) {
    state = newState;
  }

  switch (action.type) {
    case PRODUCT_UPDATE_ACTIVEID:
      state = {
        ...state,
        activeId: get(action, "id"),
      };
      break;
    case DEPOSIT_UPDATE_ACTIVEID:
      state = {
        ...state,
        depositActiveId: get(action, "id"),
      };
      break;
    case WITHDRAWAL_UPDATE_ACTIVEID:
      state = {
        ...state,
        withdrawalActiveId: get(action, "id"),
      };
      break;
    case ASSET_TAB_ACTIVEID:
      state = {
        ...state,
        tabActiveId: get(action, "id"),
      };
      break;
    case SIMULATE_ROBOT_ACTIVEID:
      state = {
        ...state,
        simulateRobotActiveId: get(action, "id"),
      };
      break;
    case APIKEY_UPDATE_ACTIVEID:
      state = {
        ...state,
        apiKeyActiveId: get(action, "id"),
      };
      break;
    case TEMP_PAGINATION:
      let robotListing = {
        previousPage: get(action, "data.page"),
        pagePosition: get(action, "data.position"),
        previousSorting: get(action, "data.sort"),
      };
      state = {
        ...state,
        robotListing,
      };
      break;
    default:
      break;
  }

  persistRedux("product", state);
  return state;
}
