import { get, isEqual, set } from "lodash";
import {
  LOGIN_SUCCESSFUL,
  LOGOUT_SUCCESSFUL,
  UPDATE_USER,
  PAIRING_LOGIN,
  LOGIN_TIME,
} from "../actions/user-actions";
import { persistRedux } from "../config";
import localStorage from "local-storage";

const INITIAL_STATE = {
  user: {},
  isAuthenticated: false,
  accessKey: "",
  loginTime: null,
  timestamp: new Date().getTime(),
  pairingLogin: false,
  pairingLoginTime: null,
};
export default function (state = INITIAL_STATE, action) {
  let persistStates =
    get(localStorage.get("redux") || {}, "user") || INITIAL_STATE;
  let newState = {
    ...state,
    ...persistStates,
  };

  if (!isEqual(state, newState)) {
    state = newState;
  }

  switch (action.type) {
    case LOGIN_SUCCESSFUL:
      set(state, "user", get(action, "user") || INITIAL_STATE.user);
      set(state, "isAuthenticated", true);
      set(state, "timestamp", get(action, "timestamp") || INITIAL_STATE.timestamp);
      set(
        state,
        "accessKey",
        get(action, "accessKey") || INITIAL_STATE.accessKey
      );
      set(state, "loginTime", new Date() || INITIAL_STATE.loginTime);
      break;

    case LOGIN_TIME:
      set(state, "loginTime", new Date() || INITIAL_STATE.loginTime);
      break;

    case LOGOUT_SUCCESSFUL:
      set(state, "user", INITIAL_STATE.user);
      set(state, "isAuthenticated", false);
      set(state, "accessKey", INITIAL_STATE.accessKey);
      set(state, "pairingLogin", false);

      break;

    case UPDATE_USER:
      state.user = {
        ...state.user,
        ...get(action, "user"),
      };
      break;

    case PAIRING_LOGIN:
      state = {
        ...state,
        pairingLogin: get(action, "status"),
        pairingLoginTime: new Date(),
      };
      break;

    default:
      break;
  }

  persistRedux("user", state);
  return state;
}
